// Agents Components
import Agent from './components/agents/Agent'
import Agente from './components/agents/Agente'
import Bonnie from './img/agents/Bonnie.png'
import LogoBrookside from './img/logos/brookside-logo.png'

// Benefis Images
import BenefitTranportation from './img/icons/001-bus.png'
import BenefitTeeth from './img/icons/002-teeth.png'
import BenefitVision from './img/icons/003-view.png'
import BenefitTelehealth from './img/icons/telehealth.png'
import BenefitFitness from './img/icons/fitness.png'
import BenefitLowDeductibles from './img/icons/lowdeductibles.png'

// Styles
import "./Styles/_App.scss"

export default function App() {
  let agent = {
    url:'',
    name: 'BONNIE K SCHANNUTH',
    first_name: 'Bonnie',
    email: 'bschannuth@aol.com',
    license: '',
    license_location: 'LICENSED AGENT IN THE STATE OF KANSAS & MISSOURI',
    youtube: '',
    photo: Bonnie,
    phone: '8168351201',
    phone_show: '816-835-1201',
    language: 'en',
    logo: LogoBrookside,
    goal_text: 'I’m certified to offer all the major Medicare Advantage companies in your area. I would love to help you find the most suitable coverage for you and specialize in working with Medicare Advantage, and Medicare supplements. In addition, I can also help you with plans for the ACA Marketplace plans and some ancillary Dental, Vision, and Hearing products.',
    learn_text:' ',
    guide_description_class: 'bold',
    guide_description: `There are forty years of insurance experience behind these wrinkles! We're ready to help! Make an appointment today!`,
    benefits: [
      {name: 'Transportation', image: BenefitTranportation},
      {name: 'Dental Coverage', image: BenefitTeeth},
      {name: 'Vision Coverage', image: BenefitVision},
      {name: 'Telehealth', image: BenefitTelehealth},
      {name: 'Fitness Benefits', image: BenefitFitness},
      {name: 'Low Deductibles', image: BenefitLowDeductibles},
    ]
  }

  return (
    <div className="">
      {(agent.language === 'en' && <Agent agent={agent} />) || (agent.language === 'es' && <Agente agent={agent} />)}
    </div>
  )
}