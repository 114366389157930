import {useState} from 'react'
import "../../Styles/_Agent.scss"
import logo from '../../img/logos/Logo.png'
import Brand from '../../img/logos/Logo2.png'
import bgContact from '../../img/agent-contact.jpg'

// Icons
import imgBus from '../../img/icons/001-bus.png'
import imgTeeth from '../../img/icons/002-teeth.png'
import imgView from '../../img/icons/003-view.png'
import imgCreditCard from '../../img/icons/004-credit-card.png'
import imgPill from '../../img/icons/005-pill.png'
import imgFastDelivery from '../../img/icons/006-fast-delivery.png'
import imgPhone from '../../img/icons/phone.png'
import imgEmail from '../../img/icons/email.png'
import React from 'react'

function Agent({agent}){
    let default_form  = {medicare:null,name:'',phone:'',email:'',postal:'',loading:false,success:false}
    if(agent.form){
        default_form = {}
        for(let p in agent.form){default_form[agent.form[p].field] = agent.form[p].default}
    }

    const [form,setForm] = useState(default_form)
    let valid_form = form.medicare!==null&&form.name&&form.phone&&form.email&&form.postal&&!form.loading ? true : false
    return (
        <div className="agent-section">
            <div className="header">
                <div className="logo">
                    <img src={agent.logo?agent.logo:logo} alt="Logo" />
                </div>

                <div className="contact">
                    <div className="info">
                        <a href={"tel:"+agent.phone}>
                            <div className="img">
                                <img src={imgPhone} alt="phone"/>
                            </div>
                            <span>{agent.phone_show}</span>
                        </a>
                        <a href={"mailto:"+agent.email}>
                            <div className="img">
                                <img src={imgEmail} alt="email"/>
                            </div>
                            <span>{agent.email}</span>
                        </a>
                    </div>
                    <div className="contact"><a className="button" href="#contact">CONTACT ME</a></div>
                </div>
            </div>

            <div className="content">
                <div className="info">
                    <div className="me">
                        <div className="name">{agent.name}</div>
                        {agent.license && <div className="rol">LICENSED INSURANCE AGENT</div>}
                        {agent.license_location && <div className="rol">{agent.license_location}</div>}
                    </div>
                    <p>Hello my name is <span className="bold">{agent.name}</span>{agent.license && <span>, {agent.license_text?agent.license_text:"I am a Florida Licensed agent with License Number"} {agent.license}</span>}. {agent.goal_text?agent.goal_text:"My daily goal is to share my knowledge in all aspects of health and safety for my clients."} <span className="bold italic">{agent.learn_text?agent.learn_text:"Learn how you can maximize your benefits today!"}</span> </p>
                    <br/>
                    {agent.guide_text && <p><span className="bold italic">{agent.guide_text}</span></p>}
                    <p>
                        <span className={agent.guide_description_class?agent.guide_description_class:''}>
                            {agent.guide_description?agent.guide_description:"Whether you want to learn about Medicare, choose your primary doctor, select a plan that best suits you based on medications, benefits and/or to improve lifestyle"}
                        </span>
                        </p>
                </div>
                <div className="video-picture">
                    <div className="border">
                        {agent.photo && <img src={agent.photo} width="100%" height="100%" />}
                        {agent.youtube && <iframe width="100%" src={"https://www.youtube.com/embed/"+agent.youtube} frameBorder="0" allow="" allowFullScreen></iframe>}
                    </div>
                    {agent.youtube && <p><span className="bold">Click</span> on the video to meet <span className="bold">{agent.first_name}</span></p>}
                </div>
            </div>

            <div className="advantages">
                <p><span className="bold">I am here to offer my help with plans of</span></p>
                <p><span className="brand">{agent.company_advantage?agent.company_advantage:"Medicare Advantage"}</span> with <span className="brand">benefits </span> like:</p>
                {agent.benefits && 
                    <ul>
                        {agent.benefits.map((benefit,benefit_index) => 
                        <li key={'benefit-'+benefit_index}>
                            {benefit.image && <img src={benefit.image} alt={benefit.name} />}
                            <p className="title">{benefit.name}</p>
                            {benefit.descriptions && 
                                <p>
                                    {benefit.descriptions.map((description,description_index)=> <span key={'description-'+benefit_index+'-'+description_index} className={description.class}>{description.text}</span>)}
                                </p>
                            }
                            {benefit.description && <p>{benefit.description}</p>}
                        </li>)}
                    </ul>
                    || 
                    <ul>
                        <li>
                            <img src={imgBus} alt="Private Transportation" />
                            <p className="title">Private Transportation</p>
                        </li>
                        <li>
                            <img src={imgTeeth} alt="Dental Coverage" />
                            <p className="title">Dental Coverage</p>
                            <p>Including denture and implants</p>
                        </li>
                        <li>
                            <img src={imgView} alt="Vision Coverage" />
                            <p className="title">Vision Coverage</p>
                            <p>Up to <span className="bold">$400</span></p>
                        </li>
                        <li>
                            <img src={imgCreditCard} alt="$50 Gift Card" />
                            <p className="title">$50 Gift Card</p>
                            <p>For healthy food</p>
                        </li>
                        <li>
                            <img src={imgPill} alt="Prescription-free medications" />
                            <p className="title">Prescription-free medications</p>
                            <p>Up to <span className="bold">$100</span></p>
                        </li>
                        <li>
                            <img src={imgFastDelivery} alt="Free delivery of your prescriptions" />
                            <p className="title">Free delivery of your prescriptions</p>
                            <p>To your home</p>
                        </li>
                    </ul>
                }
                
            </div>

            <div id="contact" className="contact-section" style={{backgroundImage: `url(${bgContact})`}}>
                <div className="form">

                    {agent.form && 

                        <form>
                            <div className="title">WOULD YOU LIKE TO SPEAK WITH {agent.first_name}?</div>
                            {agent.form.map((field,index)=> 
                                <label key={'field-'+index} className={field.type=='radio'?'multi':''}>
                                    <span>{field.name}</span>
                                    {field.type=='radio' && 
                                        <React.Fragment>
                                            {field.options.map((option,option_index)=>
                                                <React.Fragment key={'option-'+index+'-'+option_index}>
                                                <label >
                                                    <input type="radio" name={field.field} value={option.value} /> {option.name}
                                                </label>
                                                {option_index < field.options.length-1 &&  <br />}
                                                </React.Fragment>
                                             )}
                                        </React.Fragment>
                                        ||
                                        field.type=='text' && 
                                            <input type="text" onInput={e=>setForm({...form,[field.name]:e.target.value})} onChange={e=>setForm({...form,[field.name]:e.target.value})} />
                                    }
                                </label>
                            )}
                            <div className="send">
                                <button disabled={!valid_form}>SEND</button>
                            </div>

                            <div className="disclaim">
                                By completing this form you will authorize one of our specialists to contact you. Call me if you would prefer to speak immediately.
                                <a href={"tel:"+agent.phone}>{agent.phone_show}</a>
                            </div>
                        </form>

                        || 

                        <form>
                            <div className="title">WOULD YOU LIKE TO SPEAK WITH {agent.first_name}?</div>
                            <label className="multi">
                                <span>You have Medicare part of A & B</span>
                                <label>
                                    <input type="radio" value={true} name="has_medicare"  onChange={e=>setForm({...form,medicare:e.target.value})} /> Yes
                                </label>
                                <br/>
                                <label>
                                    <input type="radio" value={false} name="has_medicare" onChange={e=>setForm({...form,medicare:e.target.value})} /> No
                                </label>
                            </label>

                            <label>
                                <span>Full name</span>
                                <input type="text" onInput={e=>setForm({...form,name:e.target.value})} onChange={e=>setForm({...form,name:e.target.value})} />
                            </label>

                            <label>
                                <span>Phone number</span>
                                <input type="text" onInput={e=>setForm({...form,phone:e.target.value})} onChange={e=>setForm({...form,phone:e.target.value})}/>
                            </label>

                            <label>
                                <span>Email</span>
                                <input type="text" onInput={e=>setForm({...form,email:e.target.value})} onChange={e=>setForm({...form,email:e.target.value})}/>
                            </label>

                            <label>
                                <span>Zip code</span>
                                <input type="text" onInput={e=>setForm({...form,postal:e.target.value})} onChange={e=>setForm({...form,postal:e.target.value})}/>
                            </label>

                            <div className="send">
                                <button disabled={!valid_form}>SEND</button>
                            </div>

                            <div className="disclaim">
                                By completing this form you will authorize one of our specialists to contact you. Call me if you would prefer to speak immediately.
                                <a href={"tel:"+agent.phone}>{agent.phone_show}</a>
                            </div>
                        </form>
                    }

                </div>
                <img className="brand" src={Brand}/>
            </div>
        </div>
    )
}
export default Agent