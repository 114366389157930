import {BrowserRouter} from "react-router-dom"
import App from "./App"
   
function Router() {
    return (
      <BrowserRouter>
        <App />
      </BrowserRouter>
    )
}  
export default Router