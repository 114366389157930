import {useState} from 'react'
import "../../Styles/_Agent.scss"
import logo from '../../img/logos/Logo.png'
import Brand from '../../img/logos/Logo2.png'
import bgContact from '../../img/agent-contact.jpg'

// Icons
import imgBus from '../../img/icons/001-bus.png'
import imgTeeth from '../../img/icons/002-teeth.png'
import imgView from '../../img/icons/003-view.png'
import imgCreditCard from '../../img/icons/004-credit-card.png'
import imgPill from '../../img/icons/005-pill.png'
import imgFastDelivery from '../../img/icons/006-fast-delivery.png'
import imgPhone from '../../img/icons/phone.png'
import imgEmail from '../../img/icons/email.png'

function Agent({agent}){
    const [form,setForm] = useState({medicare:null,name:'',phone:'',email:'',postal:'',loading:false,success:false})
    let valid_form = form.medicare!==null&&form.name&&form.phone&&form.email&&form.postal&&!form.loading ? true : false
    return (
        <div className="agent-section">
            <div className="header">
                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>

                <div className="contact">
                    <div className="info">
                        <a href={"tel:"+agent.phone}>
                            <div className="img">
                                <img src={imgPhone} alt="phone"/>
                            </div>
                            <span>{agent.phone_show}</span>
                        </a>
                        <a href={"mailto:"+agent.email}>
                            <div className="img">
                                <img src={imgEmail} alt="email"/>
                            </div>
                            <span>{agent.email}</span>
                        </a>
                    </div>
                    <div className="contact"><a className="button" href="#contact">CONTÁCTAME</a></div>
                </div>
            </div>

            <div className="content">
                <div className="info">
                    <div className="me">
                        <div className="name">{agent.name}</div>
                        {agent.license && <div className="rol">AGENTE DE SEGUROS LICENCIADO</div>}
                    </div>
                    <p>Hola mi nombre es <span className="bold">{agent.name}</span>{agent.license && <span>, soy agente licenciado de La Florida con número de Licencia {agent.license}</span>}. Mi meta diaria es compartir mi conocimiento en todo los aspecto de salud y seguridad para mis clientes. <span className="bold italic">¡Maximice sus beneficios hoy!</span> </p>
                    <br/>
                    <p>Si usted desea aprender sobre Medicare, eligir su doctor primario, seleccionar un plan que le convenga basado en los medicamentos o beneficios para mejorar el estilo de vida.</p>
                </div>
                <div className="video-picture">
                    <div className="border">
                        {agent.photo && <img src={agent.photo} width="100%" height="100%" />}
                        {agent.youtube && <iframe width="100%" src={"https://www.youtube.com/embed/"+agent.youtube} frameBorder="0" allow="" allowFullScreen></iframe>}
                    </div>
                    {agent.youtube && <p>Haz <span className="bold">click</span> en el video para conocer a <span className="bold">{agent.first_name}</span></p>}
                </div>
            </div>

            <div className="advantages">
                <p><span className="bold">Estoy aquí para ofrecerte mi ayuda en planes de</span></p>
                <p><span className="brand">Medicare Advantage</span> con <span className="brand">beneficios</span> como:</p>
                <ul>
                    <li>
                        <img src={imgBus} alt="Transporte Privado" />
                        <p className="title">Transporte Privado</p>
                    </li>
                    <li>
                        <img src={imgTeeth} alt="Cobertura Dental" />
                        <p className="title">Cobertura Dental</p>
                        <p>Incluyendo Dentadura e Implantes</p>
                    </li>
                    <li>
                        <img src={imgView} alt="Cobertura de Visión" />
                        <p className="title">Cobertura de Visión</p>
                        <p>Hasta <span className="bold">$400</span></p>
                    </li>
                    <li>
                        <img src={imgCreditCard} alt="Tarjeta de Regalo de $50" />
                        <p className="title">Tarjeta de Regalo de $50</p>
                        <p>Para Compra de Comida</p>
                    </li>
                    <li>
                        <img src={imgPill} alt="Medicamentos" />
                        <p className="title">Medicamentos</p>
                        <p>Libre de receta hasta <span className="bold">$100</span></p>
                    </li>
                    <li>
                        <img src={imgFastDelivery} alt="Entrega de Medicamentos" />
                        <p className="title">Entrega de Medicamentos</p>
                        <p>Hasta su casa</p>
                    </li>
                </ul>
            </div>

            <div id="contact" className="contact-section" style={{backgroundImage: `url(${bgContact})`}}>
                <div className="form">
                    <form>
                        <div className="title">¿LE GUSTARÍA HABLAR CON {agent.first_name}?</div>
                        <label className="multi">
                            <span>Tienes Medicare parte de A & B</span>
                            <label>
                                <input type="radio" value={true} name="has_medicare"  onChange={e=>setForm({...form,medicare:e.target.value})} /> Si
                            </label>
                            <br/>
                            <label>
                                <input type="radio" value={false} name="has_medicare" onChange={e=>setForm({...form,medicare:e.target.value})} /> No
                            </label>
                        </label>

                        <label>
                            <span>Nombre Completo</span>
                            <input type="text" onInput={e=>setForm({...form,name:e.target.value})} onChange={e=>setForm({...form,name:e.target.value})} />
                        </label>

                        <label>
                            <span>Número de Teléfono</span>
                            <input type="text" onInput={e=>setForm({...form,phone:e.target.value})} onChange={e=>setForm({...form,phone:e.target.value})}/>
                        </label>

                        <label>
                            <span>Correo Electrónico</span>
                            <input type="text" onInput={e=>setForm({...form,email:e.target.value})} onChange={e=>setForm({...form,email:e.target.value})}/>
                        </label>

                        <label>
                            <span>Código Postal</span>
                            <input type="text" onInput={e=>setForm({...form,postal:e.target.value})} onChange={e=>setForm({...form,postal:e.target.value})}/>
                        </label>

                        <div className="send">
                            <button disabled={!valid_form}>ENVIAR</button>
                        </div>

                        <div className="disclaim">
                            Aceptando este formato autoriza a uno de nuestros especialistas a contactarlo. Llámenos si prefiere comunicarse inmediatamente
                            <a href={"tel:"+agent.phone}>{agent.phone_show}</a>
                        </div>
                    </form>
                </div>
                <img className="brand" src={Brand}/>
            </div>
        </div>
    )
}
export default Agent